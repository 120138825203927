<template>
  <v-layout v-if="getPermission('tool:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Tool Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                  >Name</label
                >
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100 required"
                  >Tool ID</label
                >
              </div>
              <div class="d-flex">
                <TextField
                  class="width-100"
                  id="dNamelabel"
                  dense
                  filled
                  placeholder="Name"
                  solo
                  flat
                  counter="50"
                  v-model.trim="toolCreate.name"
                  color="cyan"
                  :maxlength="250"
                  :rules="[
                    validateRules.required(toolCreate.name, 'Name'),
                    validateRules.minLength(toolCreate.name, 'Name', 1),
                    validateRules.maxLength(toolCreate.name, 'Name', 100),
                  ]"
                />
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Tool ID"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="toolCreate.barcode"
                  :rules="[
                    validateRules.required(toolCreate.barcode, 'Tool ID'),
                    validateRules.minLength(toolCreate.barcode, 'Tool ID', 3),
                    validateRules.maxLength(toolCreate.barcode, 'Tool ID', 15),
                  ]"
                ></v-text-field>
              </div>
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Category</label
                >
              </div>
              <div class="d-flex w-50">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="categoryList"
                  label="Category"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="CategoryData"
                  item-text="text"
                  item-value="text"
                  class="width-100"
                  v-model.trim="toolCreate.category"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Category(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
            </v-card-text>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="pl-2 font-weight-700 font-size-16"
                >Description</label
              >
              <TextAreaField
                auto-grow
                dense
                filled
                color="cyan"
                v-model.trim="toolCreate.description"
                :rules="[
                  validateRules.minLength(
                    toolCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    toolCreate.description,
                    'Description',
                    500
                  ),
                ]"
                placeholder="Tool Description"
                solo
                flat
                row-height="25"
                counter="250"
              />
            </v-card-text>
          </v-card>
          <template>
            <br />
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Tool Images
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100">Images </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :file-type="true"
                      :attachments="toolCreate.tool_images"
                      v-on:file:updated="updateImages"
                      acceptFile="image/png, image/jpeg, image/jpg"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="p-6 font-size-16" v-if="false">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="toolCreate.tool_attachments"
                      v-on:file:updated="updateAttachments"
                      :file-type="true"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>

    <ManageCategory
      tool
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";

import {
  QUERY,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
/* import { map, compact } from "lodash"; */

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    toolCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    ManageCategory,
  },
  data() {
    return {
      proDuplicateId: null,
      timeoutLimit: 500,
      timeout: null,
      manageCategoryDialog: false,
      categoryList: new Array(),
      barcode: null,
      toolCreate: {
        barcode: null,
        name: null,
        description: null,
        product_type: "tools",
        category: null,
        images: [],
        attachments: [],
        tool_images: [],
        tool_attachments: [],
      },
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },
    toolCreateData: {
      deep: true,
      handler(param) {
        this.toolCreate = param;
      },
    },
    toolCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveTool", _this.toolCreate);
        }, _this.timeoutLimit);

        if (_this.proDuplicateId) {
          this.toolCreate.barcode = this.barcode;
        }
      },
    },
    "toolCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    CategoryData() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.toolCreate.images = param;
    },
    updateAttachments(param) {
      this.toolCreate.attachments = param;
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: {
            product_type:
              _this.toolCreate && _this.toolCreate.product_type
                ? _this.toolCreate.product_type
                : "tools",
          },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.toolCreate.barcode
          ) {
            _this.toolCreate.barcode = response.data.barcode;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (!_this.updateMode) {
            if (
              !_this.toolId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.toolCreate.barcode = response.data.barcode;
            }
            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.toolCreate.barcode = response.data.barcode;
            }
            if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              //_this.toolCreate.description = _this.barcodeSetting.description;
            }
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
