var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getPermission('tool:create'))?_c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow product-create-height",staticStyle:{"position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-col',{staticClass:"pb-0",attrs:{"md":"10","offset-md":"1"}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v(" Tool Information ")])]),_c('v-card-text',{staticClass:"p-6 font-size-16"},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"pr-2 pl-2 font-weight-700 required font-size-16 width-100"},[_vm._v("Name")]),_c('label',{staticClass:"pl-2 font-weight-700 font-size-16 width-100 required"},[_vm._v("Tool ID")])]),_c('div',{staticClass:"d-flex"},[_c('TextField',{staticClass:"width-100",attrs:{"id":"dNamelabel","dense":"","filled":"","placeholder":"Name","solo":"","flat":"","counter":"50","color":"cyan","maxlength":250,"rules":[
                  _vm.validateRules.required(_vm.toolCreate.name, 'Name'),
                  _vm.validateRules.minLength(_vm.toolCreate.name, 'Name', 1),
                  _vm.validateRules.maxLength(_vm.toolCreate.name, 'Name', 100),
                ]},model:{value:(_vm.toolCreate.name),callback:function ($$v) {_vm.$set(_vm.toolCreate, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"toolCreate.name"}}),_c('v-text-field',{staticClass:"pl-2 width-100",attrs:{"dense":"","filled":"","color":"cyan","label":"Tool ID","solo":"","flat":"","rules":[
                  _vm.validateRules.required(_vm.toolCreate.barcode, 'Tool ID'),
                  _vm.validateRules.minLength(_vm.toolCreate.barcode, 'Tool ID', 3),
                  _vm.validateRules.maxLength(_vm.toolCreate.barcode, 'Tool ID', 15),
                ]},model:{value:(_vm.toolCreate.barcode),callback:function ($$v) {_vm.$set(_vm.toolCreate, "barcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"toolCreate.barcode"}})],1),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"pr-2 pl-2 font-weight-700 font-size-16 width-100"},[_vm._v("Category")])]),_c('div',{staticClass:"d-flex w-50"},[_c('v-autocomplete',{staticClass:"width-100",attrs:{"dense":"","filled":"","color":"cyan","item-color":"cyan","items":_vm.categoryList,"label":"Category","solo":"","flat":"","append-outer-icon":"mdi-cog","item-text":"text","item-value":"text"},on:{"click:append-outer":_vm.CategoryData},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Category(s) Found.')}})],1)],1)]},proxy:true}],null,false,3795907661),model:{value:(_vm.toolCreate.category),callback:function ($$v) {_vm.$set(_vm.toolCreate, "category", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"toolCreate.category"}})],1)])],1),_c('br'),_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v(" Description Information ")])]),_c('v-card-text',{staticClass:"p-6 font-size-16"},[_c('label',{staticClass:"pl-2 font-weight-700 font-size-16"},[_vm._v("Description")]),_c('TextAreaField',{attrs:{"auto-grow":"","dense":"","filled":"","color":"cyan","rules":[
                _vm.validateRules.minLength(
                  _vm.toolCreate.description,
                  'Description',
                  1
                ),
                _vm.validateRules.maxLength(
                  _vm.toolCreate.description,
                  'Description',
                  500
                ),
              ],"placeholder":"Tool Description","solo":"","flat":"","row-height":"25","counter":"250"},model:{value:(_vm.toolCreate.description),callback:function ($$v) {_vm.$set(_vm.toolCreate, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"toolCreate.description"}})],1)],1),[_c('br'),_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v(" Tool Images ")])]),_c('v-card-text',{staticClass:"p-6 font-size-16"},[(_vm.getPermission('attachment:create'))?_c('div',[_c('label',{staticClass:"custom-form-label width-100"},[_vm._v("Images ")]),_c('div',[_c('FileTemplate',{attrs:{"allowUpload":"","isMinDisplay":"","file-type":true,"attachments":_vm.toolCreate.tool_images,"acceptFile":"image/png, image/jpeg, image/jpg"},on:{"file:updated":_vm.updateImages}})],1)]):_vm._e()]),(false)?_c('v-card-text',{staticClass:"p-6 font-size-16"},[(_vm.getPermission('attachment:create'))?_c('div',[_c('label',{staticClass:"custom-form-label width-100"},[_vm._v("Attachments "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}],null,false,2647039722)},[_c('span',[_vm._v("More than 5 attachments are not allowed")])])],1),_c('div',[_c('FileTemplate',{attrs:{"allowUpload":"","isMinDisplay":"","attachments":_vm.toolCreate.tool_attachments,"file-type":true},on:{"file:updated":_vm.updateAttachments}})],1)]):_vm._e()]):_vm._e()],1)]],2)],1)],1),_c('ManageCategory',{attrs:{"tool":"","c-dialog":_vm.manageCategoryDialog,"category":_vm.categoryList},on:{"close-dialog":function($event){_vm.manageCategoryDialog = false},"get-product-category":_vm.getOptions}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }