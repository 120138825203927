<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'tool-create create-form'"
    v-if="getPermission('tool:create') || getPermission('tool:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="toolId">Update</template>
        <template v-else>Create</template> Tool
      </h1>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button text-white"
          color="cyan"
          v-on:click="onSubmit"
        >
          <template v-if="toolId">Update</template>
          <template v-else>Save</template> Tool
        </v-btn>
        <v-btn
          v-on:click="goBack"
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          outlined
          >Cancel
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <div class="part-create create-form" v-if="getPermission('part:create')">
        <v-form
          ref="toolForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-container fluid class="white-background">
            <ToolCreate
              :toolId="toolId"
              :barcodeData="barcode"
              :updateMode="updateMode"
              :toolCreateData="toolCreate"
              v-on:saveTool="updateTool"
            ></ToolCreate>
          </v-container>
        </v-form>
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ToolCreate from "@/view/pages/tool/partials/Create.vue";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  mixins: [CommonMixin, ProductMixin],
  name: "tool-create",
  title: "Create Tool",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      formValid: true,
      toolCreated: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      toolCreate: null,
      toolId: null,
      duplicateId: null,
    };
  },
  components: {
    ToolCreate,
    CreateUpdateTemplate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateTool(param) {
      this.toolCreate = param;
    },
    getTool(toolId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (toolId && toolId > 0) {
          _this.$store
            .dispatch(GET, { url: "tool/" + toolId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.toolForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "tool";

      if (_this.toolId && _this.toolId > 0) {
        requestType = PUT;
        requestURL = "tool/" + _this.toolId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.toolCreate })
        .then(async (response) => {
          _this.toolId = response.data ? response.data.id : null;
          (_this.toolCreated = true),
            _this.$router.push(
              _this.getDefaultRoute("tool", {
                query: {
                  status: "all",
                },
              })
            );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getTool(_this.toolId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Tool", route: "tool" },
              { title: "Update" },
            ]);

            _this.toolCreate = {
              name: response.name,
              barcode: response.barcode ? response.barcode : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              category: response.category ? response.category : null,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getTool(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.toolCreate = {
              name: response.name,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              category: response.category ? response.category : null,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.toolId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tool", route: "tool" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.tool.update" ||
      _this.$route.name == "engineer.tool.update"
    ) {
      _this.updateMode = true;

      if (_this.toolId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getTool(_this.toolId);
    });
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.toolCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
